import React, { useEffect, useState } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import { FormLabel, FormMultipleAssetSearch } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import { FormProvider, useForm } from 'react-hook-form';

import { useLooseItemSelector } from 'hooks/LooseItems';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { enrichNameToId } from 'helpers/AppHelpers';

import clsx from 'clsx';

import { defaultFilter } from '../helpers';

export default function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const [sublocationOptions, setSublocationsOptions] = useState([]);

  const methods = useForm({
    defaultValues: { ...defaultFilter.filters },
    mode: 'onChange'
  });
  const { getValues, reset, setValue } = methods;

  const { filter, filterCriteria } = useLooseItemSelector();

  const { locations, looseItemCategories, looseItems, statuses, sublocations } = filterCriteria;

  const refreshSublocationOptions = (locationIds) => {
    if (locationIds?.length) {
      const options = sublocations.filter(({ locationId }) => locationIds.includes(locationId));
      setSublocationsOptions(options);
    } else {
      setSublocationsOptions([]);
    }
  };

  useEffect(() => {
    if (open) {
      reset(filter.filters);
      refreshSublocationOptions(filter.filters?.locationIds || []);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = { ...defaultFilter.filters, searchQuery: getValues('searchQuery') };
    reset(defaultState);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  const refreshSublocationsValue = () => {
    if (!getValues('sublocationIds')?.length) return;

    if (!getValues('locationIds').length) {
      setValue('sublocationIds', []);
      return;
    }

    const locationIds = getValues('locationIds');
    const availableSublocationIds = locations
      .filter(({ id }) => locationIds.includes(id))
      .flatMap(({ sublocations }) => sublocations)
      .map(({ id }) => id);

    const filteredSublocations = getValues('sublocationIds').filter(({ id }) =>
      availableSublocationIds.includes(id)
    );

    setValue('sublocationIds', filteredSublocations);
  };

  const handleLocationSelect = (_, value) => {
    refreshSublocationsValue();

    refreshSublocationOptions(value);
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Current Location</FormLabel>
                <FormAutoMultiSelect
                  name="locationIds"
                  menuItems={locations || []}
                  options={{
                    label: 'siteCode',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: handleLocationSelect
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Sublocation</FormLabel>
                <FormAutoMultiSelect
                  name="sublocationIds"
                  menuItems={sublocationOptions || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    isDisabled: !getValues('locationIds')?.length
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Job Number</FormLabel>
                <FormAutoMultiSelect
                  name="locationJobNumberIds"
                  menuItems={locations || []}
                  options={{
                    label: 'locationJobNumber',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Contained In</FormLabel>
                <FormMultipleAssetSearch
                  name="assetIds"
                  listName="assetList"
                  strategy="fixed"
                  getQueryOptions={(searchQuery) => ({
                    pagination: { limit: 40, page: 1 },
                    filters: { isDeleted: false, showAssigned: false, searchQuery }
                  })}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Loose Item</FormLabel>
                <FormAutoMultiSelect
                  name="looseItemIds"
                  menuItems={looseItems || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Category</FormLabel>
                <FormAutoMultiSelect
                  name="categoryIds"
                  menuItems={looseItemCategories || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Status</FormLabel>
                <FormMultiSelect
                  trackById
                  name="statusNames"
                  options={enrichNameToId(statuses) || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Show with BAA qty</FormLabel>
                <FormCheckbox name="showWithBaa" />
              </div>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Show with reorder trigger</FormLabel>
                <FormCheckbox name="showWithReorderTrigger" />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
