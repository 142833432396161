import { TICKET_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj, getUserFullName, pluck } from 'helpers/AppHelpers';
import {
  createChip,
  handleBooleanFilter,
  handleDateFilter,
  handleIdsFilter,
  handleNamesFilter
} from 'helpers/ChipGenerator';
import { useSearchParams } from 'hooks/useSearchParams';

const defaultFilter = {
  filters: {
    assetIds: [],
    assetList: [],
    assetTypeIds: [],
    locationIds: [],
    locationJobNumberIds: [],
    prefixIds: [],
    rootCauseIds: [],
    severities: [],
    ticketTypeIds: [],
    assignedUserIds: [],
    assignedUserList: [],
    isOpened: true,
    searchQuery: '',
    shouldIncludeDeletedAssets: false,
    showAssigned: false,
    openedFrom: null,
    openedTo: null,
    closedFrom: null,
    closedTo: null,
    reopenedFrom: null,
    reopenedTo: null,
    dashboardId: '',
    dashboardDurationId: '',
    dashboardType: ''
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const useTicketSummaryParams = () => {
  return useSearchParams([
    'searchQuery',
    'dashboardType',
    'dashboardId',
    'dashboardDurationId',
    'severityName'
  ]);
};

const generateInitialQuery = ({
  filterCriteria,
  getTableLimit,
  isAllLimitEnabled,
  filter,
  searchQuery,
  dashboardType,
  dashboardId,
  dashboardDurationId,
  severityName
}) => {
  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(TICKET_LIST_TABLE_PARAMETER);

  const initialQuery = {
    filters: { ...defaultFilter.filters },
    pagination: { limit, page: defaultPage },
    sortRules: filter?.sortRules || defaultFilter.sortRules,
    responseReceived: false
  };

  const setFilters = (customFilters) => ({
    ...initialQuery,
    filters: { ...initialQuery.filters, ...customFilters }
  });

  if (dashboardType && dashboardId && dashboardDurationId) {
    const severitiesValue =
      severityName && pluck(filterCriteria.severities, 'name').includes(severityName)
        ? [severityName]
        : [];

    return setFilters({
      dashboardType,
      dashboardId,
      dashboardDurationId,
      severities: severitiesValue
    });
  }

  if (searchQuery) {
    return setFilters({ searchQuery });
  }

  const isAll = isAllLimitEnabled(TICKET_LIST_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    ...initialQuery,
    filters: { ...initialQuery.filters, ...filter?.filters },
    pagination: { limit, page }
  };
};

const OPENED_PREFIX = 'Opened';
const CLOSED_PREFIX = 'Closed';
const REOPENED_PREFIX = 'Will reopen';

const generateChips = (options, filters = {}) => {
  const { locations, prefixes, assetTypes, rootCauses, severities, ticketTypes, users, assets } =
    options;

  const {
    assetTypeIds,
    assetIds,
    assignedUserIds,
    locationIds,
    locationJobNumberIds,
    prefixIds,
    rootCauseIds,
    ticketTypeIds,
    isOpened,
    showAssigned,
    openedFrom,
    openedTo,
    closedFrom,
    closedTo,
    dashboardId,
    dashboardType,
    dashboardDurationId,
    reopenedFrom,
    reopenedTo
  } = filters;

  let newChips = [];

  const filterConfigs = [
    { ids: assetTypeIds, options: assetTypes, getLabel: (el) => el.name, key: 'assetTypeIds' },
    { ids: locationIds, options: locations, getLabel: (el) => el.siteCode, key: 'locationIds' },
    {
      ids: locationJobNumberIds,
      options: locations,
      getLabel: (el) => el.locationJobNumber,
      key: 'locationJobNumberIds'
    },
    { ids: prefixIds, options: prefixes, getLabel: (el) => el.prefix, key: 'prefixIds' },
    { ids: rootCauseIds, options: rootCauses, getLabel: (el) => el.name, key: 'rootCauseIds' },
    { ids: ticketTypeIds, options: ticketTypes, getLabel: (el) => el.name, key: 'ticketTypeIds' },
    {
      ids: assignedUserIds,
      options: users,
      getLabel: (el) => getUserFullName(el),
      key: 'assignedUserIds'
    },
    {
      ids: assetIds,
      options: assets,
      getLabel: (el) => el.drCode,
      key: 'assetIds'
    }
  ];

  filterConfigs.forEach((config) => {
    if (config?.ids?.length) {
      newChips = newChips.concat(
        handleIdsFilter(config.ids, config.options, config.getLabel, config.key)
      );
    }
  });

  if (filters?.severities?.length) {
    newChips = newChips.concat(handleNamesFilter(filters.severities, severities, 'severities'));
  }

  if (isOpened !== undefined && !isOpened) {
    newChips = newChips.concat(handleBooleanFilter('isOpened', 'Show Closed Tickets'));
  }

  if (showAssigned) {
    newChips = newChips.concat(handleBooleanFilter('showAssigned', 'Show assigned'));
  }

  // hided on stage env
  // if (shouldIncludeDeletedAssets) {
  //   newChips = newChips.concat(
  //     handleBooleanFilter('shouldIncludeDeletedAssets', 'Show Tickets with Deleted Assets')
  //   );
  // }

  if (dashboardId && dashboardType && dashboardDurationId) {
    const labels = {
      TicketsBaseStatistic: 'Ticket Basic Statistic Widget',
      AverageTicketTurnover: 'Average Ticket Turnover Widget',
      TicketFlowStatistic: 'Ticket Flow Statistic Widget'
    };

    newChips = newChips.concat([createChip('filteredByTicketDashboard', labels[dashboardType])]);
  }

  if (openedFrom || openedTo) {
    newChips = newChips.concat(handleDateFilter(openedFrom, openedTo, OPENED_PREFIX));
  }

  if (closedFrom || closedTo) {
    newChips = newChips.concat(handleDateFilter(closedFrom, closedTo, CLOSED_PREFIX));
  }

  if (reopenedFrom || reopenedTo) {
    newChips = newChips.concat(handleDateFilter(reopenedFrom, reopenedTo, REOPENED_PREFIX));
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemName, itemId } = deletedChip;

  switch (key) {
    case 'isOpened':
      newFilters[key] = true;
      newFilters.closedFrom = null;
      newFilters.closedTo = null;
      break;
    case 'showAssigned':
    case 'shouldIncludeDeletedAssets':
      newFilters[key] = false;
      break;
    case 'severities':
      newFilters[key] = newFilters[key].filter((name) => name !== itemName);
      break;
    case `duration_${OPENED_PREFIX}`:
      newFilters.openedTo = null;
      newFilters.openedFrom = null;
      break;
    case `duration_${CLOSED_PREFIX}`:
      newFilters.closedTo = null;
      newFilters.closedFrom = null;
      break;
    case `duration_${REOPENED_PREFIX}`:
      newFilters.reopenedTo = null;
      newFilters.reopenedFrom = null;
      break;
    case `dateFrom_${OPENED_PREFIX}`:
      newFilters.openedFrom = null;
      break;
    case `dateFrom_${CLOSED_PREFIX}`:
      newFilters.closedFrom = null;
      break;
    case `dateFrom_${REOPENED_PREFIX}`:
      newFilters.reopenedFrom = null;
      break;
    case `dateTo_${OPENED_PREFIX}`:
      newFilters.openedTo = null;
      break;
    case `dateTo_${CLOSED_PREFIX}`:
      newFilters.closedTo = null;
      break;
    case `dateTo_${REOPENED_PREFIX}`:
      newFilters.reopenedTo = null;
      break;
    case 'assignedUserIds':
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      newFilters.assignedUserList = newFilters.assignedUserList.filter(({ id }) => id !== itemId);
      break;
    case 'assetIds':
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      newFilters.assetList = newFilters.assetList.filter(({ id }) => id !== itemId);
      break;
    case 'filteredByTicketDashboard':
      newFilters.dashboardId = '';
      newFilters.dashboardType = '';
      newFilters.dashboardDurationId = '';
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = [
    'searchQuery',
    'assetIds',
    'assetTypeIds',
    'locationIds',
    'locationJobNumberIds',
    'prefixIds',
    'rootCauseIds',
    'severities',
    'ticketTypeIds',
    'assignedUserIds',
    'openedFrom',
    'openedTo',
    'closedFrom',
    'closedTo',
    'reopenedFrom',
    'reopenedTo',
    'showAssigned',
    'shouldIncludeDeletedAssets'
  ];

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  payload.filters.isOpened = query.filters.isOpened;

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  if (
    query.filters?.dashboardId &&
    query.filters?.dashboardDurationId &&
    query.filters?.dashboardType
  ) {
    setFilters('dashboardId', query.filters.dashboardId);
    setFilters('dashboardDurationId', query.filters.dashboardDurationId);
    setFilters('dashboardType', query.filters.dashboardType);
  }

  return payload;
};

const columnSizesConfig = {
  // fixed
  id: { mobile: 85, desktop: 85 },
  severity: { mobile: 100, desktop: 100 },
  // percent
  location: { mobile: 200, percent: 10 },
  asset: { mobile: 200, percent: 8 },
  type: { mobile: 200, percent: 10 },
  rootCause: { mobile: 200, percent: 10 },
  description: { mobile: 200, percent: 22 },
  openedByUser: { mobile: 200, percent: 20 },
  openedAtUtc: { mobile: 200, percent: 20 }
};

export {
  defaultFilter,
  useTicketSummaryParams,
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery,
  columnSizesConfig
};
