import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormDayPicker, FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import { FormMultipleAssetSearch, FormMultipleUserSearch } from 'components/FormComponents';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { FormProvider, useForm } from 'react-hook-form';
import { useTicketSelector } from 'hooks/Ticket';

import { enrichNameToId } from 'helpers/AppHelpers';

import { defaultFilter } from '../helpers';

import clsx from 'clsx';

export default function TicketsFilter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    ...defaultFilter.filters,
    mode: 'onChange'
  });
  const { getValues, reset, setValue, watch } = methods;

  const { filter, filterCriteria } = useTicketSelector();

  const { locations, prefixes, assetTypes, rootCauses, severities, ticketTypes } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = { ...defaultFilter.filters, searchQuery: getValues('searchQuery') };
    reset(defaultState);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  const openedFromWatcher = watch('openedFrom');
  const openedToWatcher = watch('openedTo');
  const isOpenedWatcher = watch('isOpened');
  const closedFromWatcher = watch('closedFrom');
  const closedToWatcher = watch('closedTo');
  const reopenedFromWatcher = watch('reopenedFrom');
  const reopenedToWatcher = watch('reopenedTo');

  const checkClosedDates = () => {
    setValue('closedFrom', null);
    setValue('closedTo', null);
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.dialog} id="root-portal">
        {isMobile && <FilterHeader title="Filters" onBack={closePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Location</FormLabel>
                <FormAutoMultiSelect
                  name="locationIds"
                  menuItems={locations || []}
                  options={{ label: 'siteCode', disableByObjectTracker: true, disableLabel: true }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Job Number</FormLabel>
                <FormAutoMultiSelect
                  name="locationJobNumberIds"
                  menuItems={locations || []}
                  options={{
                    label: 'locationJobNumber',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Ticket Type</FormLabel>
                <FormAutoMultiSelect
                  name="ticketTypeIds"
                  menuItems={ticketTypes || []}
                  options={{ label: 'name', disableByObjectTracker: true, disableLabel: true }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Severity</FormLabel>
                <FormMultiSelect
                  trackById
                  name="severities"
                  options={enrichNameToId(severities) || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Root Cause</FormLabel>
                <FormAutoMultiSelect
                  name="rootCauseIds"
                  menuItems={rootCauses || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Prefix</FormLabel>
                <FormAutoMultiSelect
                  name="prefixIds"
                  menuItems={prefixes || []}
                  options={{ label: 'prefix', disableByObjectTracker: true, disableLabel: true }}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Asset Type</FormLabel>
                <FormMultiSelect
                  trackById
                  name="assetTypeIds"
                  options={assetTypes || []}
                  onSelect={handleMultiSelect}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Asset</FormLabel>
                <FormMultipleAssetSearch
                  name="assetIds"
                  listName="assetList"
                  getQueryOptions={(searchQuery) => ({
                    pagination: { limit: 40, page: 1 },
                    filters: { isDeleted: false, showAssigned: false, searchQuery }
                  })}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Assignee</FormLabel>
                <FormMultipleUserSearch
                  name="assignedUserIds"
                  listName="assignedUserList"
                  getQueryOptions={(searchQuery) => ({
                    pagination: { limit: 40, page: 1 },
                    filters: { shouldIncludeCurrentUser: true, searchQuery }
                  })}
                />
              </div>
              {/* {!isMobile && (
                <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                  <FormLabel>Show Tickets with Deleted Assets</FormLabel>
                  <FormCheckbox name="shouldIncludeDeletedAssets" />
                </div>
              )} */}
            </div>
            <div className={styles.form__block}>
              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Show assigned, not closed tickets</FormLabel>
                <FormCheckbox name="showAssigned" />
              </div>

              <div className={styles.form__block_cell}>
                <FormLabel>Opened from</FormLabel>
                <FormDayPicker name="openedFrom" max={openedToWatcher || new Date()} />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Opened to</FormLabel>
                <FormDayPicker name="openedTo" min={openedFromWatcher} max={new Date()} />
              </div>
            </div>
            <div className={styles.form__block}>
              {/* {isMobile && (
                <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                  <FormLabel>Show Tickets with Deleted Assets</FormLabel>
                  <FormCheckbox name="shouldIncludeDeletedAssets" />
                </div>
              )} */}

              <div className={clsx(styles.form__block_cell, styles.checkbox)}>
                <FormLabel>Show Closed Tickets</FormLabel>
                <FormCheckbox reversed name="isOpened" onSelectTriggered={checkClosedDates} />
              </div>

              <div className={styles.form__block_cell}>
                <FormLabel>Closed from</FormLabel>
                <FormDayPicker
                  name="closedFrom"
                  max={closedToWatcher || new Date()}
                  disabled={isOpenedWatcher}
                />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Closed to</FormLabel>
                <FormDayPicker
                  name="closedTo"
                  min={closedFromWatcher}
                  max={new Date()}
                  disabled={isOpenedWatcher}
                />
              </div>
            </div>
            <div className={styles.form__block}>
              <div className={styles.form__block_cell}>
                <FormLabel>Will reopen from</FormLabel>
                <FormDayPicker name="reopenedFrom" min={new Date()} max={reopenedToWatcher} />
              </div>
              <div className={styles.form__block_cell}>
                <FormLabel>Will reopen to</FormLabel>
                <FormDayPicker name="reopenedTo" min={reopenedFromWatcher || new Date()} />
              </div>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={closePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
