import React, { useState } from 'react';
import styles from './Header.module.scss';

import StyledLink from 'components/StyledLink';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import { IconButton } from '@material-ui/core';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useHistory } from 'react-router-dom';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import {
  BATCH_ASSET_UPDATES_PATH,
  HOME_PATH,
  LOCATION_REPORT_LIST_PATH,
  PERSONNEL_PROFILE_PATH,
  REQUEST_ORDER_EDIT_PATH,
  RELEASE_NOTES_PATH
} from 'constants/routeConstants';
import { EXIT_WITHOUT_SAVING } from 'constants/dialogPopupsData';

import QuickAccessButton from './QuickAccessButton';

import data from '../../../package.json';

const Header = React.memo(function Header() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const [modalData, setModalData] = useState({});

  const { isTeamMemberUser } = useUserConfig();
  const { unsavedFormData } = useCommonSelector();
  const { setUnsavedFormDataAction } = useCommonActions();

  const onProfileClick = () => {
    if (unsavedFormData) {
      setModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true, wasClicked: 'profile' });
    } else {
      history.push(PERSONNEL_PROFILE_PATH);
    }
  };

  const handleRedirect = (path, pathToCompare) => {
    if (window.location.pathname === pathToCompare) {
      history.push(path);
      window.location.reload();
    } else {
      history.push(path);
    }
  };
  const handleAccessButtonClick = (path, comparingPath) => {
    if (unsavedFormData) {
      setModalData({
        ...EXIT_WITHOUT_SAVING,
        isOpened: true,
        wasClicked: 'access-button',
        path,
        comparingPath
      });
    } else {
      handleRedirect(path, comparingPath);
    }
  };

  const closeModal = () => setModalData();
  const agreeModal = () => {
    switch (modalData.wasClicked) {
      case 'profile':
        setUnsavedFormDataAction(false);
        history.push(PERSONNEL_PROFILE_PATH);
        break;
      case 'access-button':
        setUnsavedFormDataAction(false);
        handleRedirect(modalData.path, modalData.comparingPath);
        break;
      default:
        break;
    }
    closeModal();
  };

  const buttons = [
    {
      id: 1,
      title: 'Location Report',
      path: `${LOCATION_REPORT_LIST_PATH}?shouldOpenPopup=true`,
      pathToCompare: LOCATION_REPORT_LIST_PATH
    },
    {
      id: 2,
      title: 'Add ticket',
      path: `${BATCH_ASSET_UPDATES_PATH}?tab=addAssetTicket`,
      pathToCompare: BATCH_ASSET_UPDATES_PATH
    },
    {
      id: 3,
      title: 'Verify Location',
      path: `${BATCH_ASSET_UPDATES_PATH}?tab=locationVerification`,
      pathToCompare: BATCH_ASSET_UPDATES_PATH
    },
    {
      id: 4,
      title: 'Request Order',
      path: REQUEST_ORDER_EDIT_PATH,
      pathToCompare: REQUEST_ORDER_EDIT_PATH
    }
  ];

  const env =
    process.env.REACT_APP_ENVIRONMENT === 'prod'
      ? 'test'
      : process.env.REACT_APP_ENVIRONMENT || 'local';
  const getEnvTitle = () => (env === 'production' ? '' : env + ' env');

  const handleLogoClick = () => history.push(HOME_PATH);

  return isMobile ? null : (
    <section className={styles.header}>
      <IconButton className={styles.header__logo} onClick={handleLogoClick}>
        <LogoIcon />
      </IconButton>

      <div className={styles.header__quick_buttons}>
        {buttons.map(({ id, title, path, pathToCompare }) => (
          <QuickAccessButton
            key={id}
            title={title}
            onClick={() => handleAccessButtonClick(path, pathToCompare)}
          />
        ))}
      </div>

      <span className={styles.header__title}>{getEnvTitle()}</span>

      <div className={styles.header__options}>
        <StyledLink to={RELEASE_NOTES_PATH} className={styles.header__version}>
          v. {data.version}
        </StyledLink>
        {isTeamMemberUser && (
          <div className={styles.header__profile} onClick={onProfileClick}>
            <ProfileIcon />
          </div>
        )}
      </div>

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
    </section>
  );
});

export default Header;
